"use client";

import { useState, useEffect } from "react";
import Modal from 'react-modal';
import Link from 'next/link';
import { Session } from "next-auth";
import SignInForm from '@/components/SignInForm';
import styles from "./Header.module.css";
import { useLocalStorage } from 'react-use';
import Image from "next/image";
import { useRouter } from 'next/navigation';

const isInWhiteList = (session: Session) => {
  console.log('XXX session:', session);
  if (['sergei.frontend@gmail.com', 'kask.martin@gmail.com'].includes(session.user.email || '')) {
    return true;
  }
  return false;
}

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0px',
    border: '#f00 0px solid',
    borderRadius: 10,
    transform: 'translate(-50%, -50%)',
    background: 'rgb(var(--background-end-rgb))',
    // background: `linear-gradient(
    //   to bottom,
    //   transparent,
    //   rgb(var(--background-end-rgb))
    // )
    // rgb(var(--background-start-rgb))`,
  },
};

export default function Header({ session }: { session: Session | null }) {
  const router = useRouter();
  const [modalIsOpen, setIsOpen] = useState(false);

  const [isSignupFormFilled, setIsSignupFormFilled] = useLocalStorage('isSignupFormFilled', session && session?.user.metadata ? JSON.parse(session?.user?.metadata).isSignupFormFilled : false);

  console.log('XXX isSignupFormFilled:', isSignupFormFilled);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (session?.user) {
      if (!isSignupFormFilled) {
        router.push('/profile/complete');
      }
    }
  }, [session, isSignupFormFilled]);

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <div className={styles.naviContainer}>
          {/* <Link href={"/"} className={styles.link}>
            Features
          </Link>
          <Link href={"/app"} className={styles.link}>CloneBot</Link> */}
          <Link
            href={"/team"}
            className={styles.link}
          >
            Team
          </Link>

          {session ?
          <>

            {isInWhiteList(session) ? <Link
              href="/designs"
              className={styles.link}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div>
                DESIGNS
              </div>
            </Link>: null}
            <Link
              href="/profile"
              className={styles.profilelink}
            >
              {session.user ? <div>
                <div>{session.user.name}</div>
                <div className={styles.personPictureContainer}>
                  <div className={styles.personPictureBorder}>
                    <div
                      className={styles.personPicture}
                      style={{
                        backgroundImage: `url("${session.user.image}")`,
                      }}
                    />

                  </div>
                </div>
              </div>
                :
                null}
            </Link>
            </>

            : <Link
              href="/auth/login"
              className={styles.link}
            >
              <button
                className={styles.signInButton}
                onClick={(e) => {
                  e.preventDefault();
                  openModal();
                }}
              >
                Login
              </button>
            </Link>}
        </div>
        <div className={styles.logoContainer}>
          <div
            className={styles.bioText}
          >
            BIO
          </div>
          <Link href="/" className="-m-1.5 p-1.5">
            <Image className={styles.logo} src="/logo.svg" alt="" width={70} height={100} />
          </Link>
          <div
            className={styles.nexioText}
          >
            NEXIO
          </div>
        </div>
      </nav >
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => { console.log('XXX onAfterOpen') }}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <SignInForm signOut={false} />
      </Modal>
    </header >
  );
}
